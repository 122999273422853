import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser } from '../../utils/common'
import { GET_organization_profile,ApiUpdateOrg,Apigenerate_cpd_pdf } from '../../redux/actions/organizations'
import { ApiGetProvince,ApiGetDistrict,ApiGetSub_district,ApiGetOrgType,getTitle } from '../../redux/actions/master'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import { GrStatusGoodSmall } from 'react-icons/gr'



const Div = styled.div`

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-sub {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
`

const DivInfo = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }
  .TextField_employee {
    width: 100%;
  }
  .button_Edit {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #eaecf0;
  }
  .button_Cancel {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    margin-right: 1rem;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
  }

  .ql-container {
    min-height: 250px;
    font-family: inherit;
  }
  .md-right-1rem{
    margin-right: 1rem;
  }
  .box_org_status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .group_filter {
      display: flex;
      /*align-items: center;*/
    }
  }
  .css_filter_name {
    width: 150px;
    margin-right: 10px;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color:#475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }
`



const OrgProfile = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [OldDataInfo, setOldDataInfo] = useState(null)
  const [EditBox1, setEditBox1] = useState(false)
  const [EditBox2, setEditBox2] = useState(false)
  const [EditBox3, setEditBox3] = useState(false)
  const [EditBox4, setEditBox4] = useState(false)
  const [org_type_option, setorg_type_option] = useState([])
  const [province_option, setprovince_option] = useState([])
  const [district_option, setdistrict_option] = useState([])
  const [sub_district_option, setsub_district_option] = useState([])
  const [title_option, settitle_option] = useState([])

  useEffect(() => {
    dispatch(getTitle()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newtitle_option = data_info.data.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            //console.log(newtitle_option)
            settitle_option([...newtitle_option])
        }
    })
  }, [dispatch])


  useEffect(() => {
    dispatch(ApiGetOrgType()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setorg_type_option([...neworg_type_option])
        }
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(ApiGetProvince()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetprovince_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setprovince_option([...newsetprovince_option])
        }
    })
  }, [dispatch])

  const fetchDataDistrict = useCallback(
    async (filter) => {
      dispatch(ApiGetDistrict(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetdistrict_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setdistrict_option([...newsetdistrict_option]);    
        }
      })
    },
    [dispatch]
  )

  const fetchDataSubDistrict = useCallback(
    async (filter) => {
      dispatch(ApiGetSub_district(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newsetsub_district_option= data_info?.data?.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            setsub_district_option([...newsetsub_district_option]);    
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(GET_organization_profile()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){

          if(data_info.data_info?.province_id){
            const filter = {
              skip: 0,
              limit: 100,
              province_id : data_info.data_info?.province_id
            }
            fetchDataDistrict(filter)
            if(data_info.data_info?.district_id){
              const filter_district = {
                skip: 0,
                limit: 100,
                district_id : data_info.data_info?.district_id
              }
              fetchDataSubDistrict(filter_district);
            }
          }

          setDataInfo(data_info.data_info);
          setOldDataInfo(data_info.data_info);
          setisOpenData(true)
        }
        
      }
    })
  }, [dispatch,fetchDataDistrict,fetchDataSubDistrict])



  const onProvince = (v) => {
    if(v){
      if(v != OldDataInfo.province_id){
        const filter = {
          skip: 0,
          limit: 100,
          province_id : v
        }
        fetchDataDistrict(filter)
        setDataInfo({ ...DataInfo, province_id: v ,district_id : '', sub_district_id : '' })
        setsub_district_option([])
      }  
    }
  }

  const onDistrict = (v) => {
    if(v){
      if(v != OldDataInfo.district_id){
        const filter = {
          skip: 0,
          limit: 100,
          district_id : v
        }
        fetchDataSubDistrict(filter)
        setDataInfo({ ...DataInfo, district_id : v, sub_district_id : '' })
      }  
    }
  }

  const onSubDistrict = (v) => {
    if(v){
      if(v != OldDataInfo.sub_district_id){
        setDataInfo({ ...DataInfo, sub_district_id : v })
      }  
    }
  }

  const Savechanges = (id_box_edit) => {
    const this_meeting_id = DataInfo.id;
    let sent_data_api = {
        name_th: DataInfo.name_th,
        name_abbr: DataInfo.name_abbr,
        tin: DataInfo.tin,
        org_type: DataInfo.org_type,
        org_phone_no: DataInfo.org_phone_no,
        website: DataInfo.website,
        house_no: DataInfo.house_no,
        building: DataInfo.building,
        village_no: DataInfo.village_no,
        alley: DataInfo.alley,
        street: DataInfo.street,
        province_id: DataInfo.province_id,
        district_id: DataInfo.district_id,
        sub_district_id: DataInfo.sub_district_id,
        postal_code: DataInfo.postal_code,
    }
    if(id_box_edit == 3){
      sent_data_api = {
        contact_person_prefix_id: DataInfo.contact_person_prefix_id,
        contact_person_first_name: DataInfo.contact_person_first_name,
        contact_person_last_name: DataInfo.contact_person_last_name,
        contact_person_position: DataInfo.contact_person_position,
        contact_person_phone_no: DataInfo.contact_person_phone_no,
        contact_person_email: DataInfo.contact_person_email,
      }
    }
    dispatch(ApiUpdateOrg(this_meeting_id, sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        if (id_box_edit == 1) {
          setEditBox1(false)
        } else if (id_box_edit == 2) {
          setEditBox2(false)
        } else if (id_box_edit == 3) {
          setEditBox3(false)
        } else if (id_box_edit == 4) {
          setEditBox4(false)
        }
      }
    })
  }

  const OpenPDFFile = () => {
    dispatch(Apigenerate_cpd_pdf()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          window.open(data_info.fullpath, "_blank");
        }        
      }
    })
  }

  const onTitlecontact_person_prefix_id = (v) => {
    if(v){
      setDataInfo({ ...DataInfo, contact_person_prefix_id: v })
    }
  }

  return (
    <Div>
      <header>
        <div className="content-title">
          ข้อมูลองค์กรแม่ข่าย
        </div>
        <div className="content-title-sub">
        {user?.organizations_name ? user?.organizations_name : '-'}
        </div>
        <div className="border_header"></div>
        {isOpenData == true && (
          <DivInfo>
            <div className="main_box">
              <div>
                <div className="main_box_text_head">ข้อมูลเกี่ยวกับองค์กรแม่ข่าย</div>
                <div className="main_box_text_sub">ข้อมูลทั่วไปขององค์กรแม่ข่าย</div>
              </div>
              <div>
                <div className="main_box_edit">
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">ชื่อองค์กรแม่ข่าย</div>
                      <div>
                        <TextField
                          name="name_th"
                          className="TextField_employee"
                          value={DataInfo.name_th}
                          onChange={(e) => setDataInfo({ ...DataInfo, name_th: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">ชื่อย่อองค์กรแม่ข่าย</div>
                      <div>
                        <TextField
                          name="name_abbr"
                          className="TextField_employee"
                          value={DataInfo.name_abbr}
                          onChange={(e) => setDataInfo({ ...DataInfo, name_abbr: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">เลขประจำตัวผู้เสียภาษี 13 หลัก</div>
                      <div>
                        <TextField
                          name="tin"
                          className="TextField_employee"
                          value={DataInfo.tin}
                          onChange={(e) => setDataInfo({ ...DataInfo, tin: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">ประเภทองค์กร</div>
                      <div>
                        <Dropdown
                            id={`dd_org_type`}
                            value={DataInfo.org_type}
                            optionList={org_type_option}
                            onChange={(v) => setDataInfo({ ...DataInfo, org_type: v })}
                            placeHolder={'เลือกประเภทองค์กร'}
                            disabled={!EditBox1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">เบอร์โทรศัพท์องค์กร</div>
                      <div>
                        <TextField
                          name="org_phone_no"
                          className="TextField_employee"
                          value={DataInfo.org_phone_no}
                          onChange={(e) => setDataInfo({ ...DataInfo, org_phone_no: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">เว็ปไซด์หน่วยงาน</div>
                      <div>
                        <TextField
                          name="website"
                          className="TextField_employee"
                          value={DataInfo.website}
                          onChange={(e) => setDataInfo({ ...DataInfo, website: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">ที่อยู่องค์กร บ้านเลขที่</div>
                      <div>
                        <TextField
                          name="house_no"
                          className="TextField_employee"
                          value={DataInfo.house_no}
                          onChange={(e) => setDataInfo({ ...DataInfo, house_no: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">อาคาร/หมู่บ้าน</div>
                      <div>
                        <TextField
                          name="building"
                          className="TextField_employee"
                          value={DataInfo.building}
                          onChange={(e) => setDataInfo({ ...DataInfo, building: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">หมู่</div>
                      <div>
                        <TextField
                          name="village_no"
                          className="TextField_employee"
                          value={DataInfo.village_no}
                          onChange={(e) => setDataInfo({ ...DataInfo, village_no: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">ซอย</div>
                      <div>
                        <TextField
                          name="alley"
                          className="TextField_employee"
                          value={DataInfo.alley}
                          onChange={(e) => setDataInfo({ ...DataInfo, alley: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">ถนน</div>
                      <div>
                        <TextField
                          name="street"
                          className="TextField_employee"
                          value={DataInfo.street}
                          onChange={(e) => setDataInfo({ ...DataInfo, street: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">จังหวัด</div>
                      <div>
                          <Dropdown
                            id={`dd_province_id`}
                            value={DataInfo.province_id}
                            optionList={province_option}
                            onChange={onProvince}
                            placeHolder={'เลือกจังหวัด'}
                            disabled={!EditBox1}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">อำเภอ / เขต</div>
                      <div>
                          <Dropdown
                            id={`dd_district_id`}
                            value={DataInfo.district_id}
                            optionList={district_option}
                            onChange={onDistrict}
                            placeHolder={'เลือกอำเภอ / เขต'}
                            disabled={!EditBox1}
                          />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">ตำบล / แขวง</div>
                      <div>
                          <Dropdown
                            id={`dd_sub_district_id`}
                            value={DataInfo.sub_district_id}
                            optionList={sub_district_option}
                            onChange={onSubDistrict}
                            placeHolder={'เลือกตำบล / แขวง'}
                            disabled={!EditBox1}
                          />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">รหัสไปรษณีย์</div>
                      <div>
                        <TextField
                          name="postal_code"
                          className="TextField_employee"
                          value={DataInfo.postal_code}
                          onChange={(e) => setDataInfo({ ...DataInfo, postal_code: e.target.value })}
                          disabled={!EditBox1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border_buttom"></div>
                  <div className="main_box_button">
                    {EditBox1 == false && (
                      <div>
                        <Button onClick={() => setEditBox1(true)} >
                          แก้ไขข้อมูล
                        </Button>
                      </div>
                    )}
                    {EditBox1 == true && (
                      <div>
                        <ButtonSecondary onClick={() => setEditBox1(false)} className="md-right-1rem">
                          ยกเลิก
                        </ButtonSecondary>
                        <Button onClick={() => Savechanges(1)}>บันทึก</Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="border_header"></div>
            <div className="main_box">
              <div>
                <div className="main_box_text_head">สถานภาพสมาชิกแม่ข่าย</div>
                <div className="main_box_text_sub">ข้อมูลเกี่ยวกับสมาชิกภาพของแม่ข่าย</div>
              </div>
              <div>
                <div className="main_box_edit">
                  <div className="main_box_fill_data">
                    <div className="box_org_status">
                      <div className="group_filter">
                        <div className="css_filter_name">
                          <div className="topic_fill">รหัสองค์กรแม่ข่าย</div>
                          <div>
                            <TextField
                              name="code"
                              value={DataInfo.code}
                              onChange={(e) => setDataInfo({ ...DataInfo, code: e.target.value })}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div >
                          <div className="topic_fill">สถานภาพสมาชิก</div>
                          <div>
                            {DataInfo.organizations_status != '-' ? (
                                <div
                                  className="status-badge"
                                  style={{
                                    color: DataInfo?.badge_color?.color,
                                    borderColor: DataInfo?.badge_color?.border,
                                    backgroundColor: DataInfo?.badge_color?.background,
                                  }}
                                >
                                  <GrStatusGoodSmall 
                                    className="status"
                                    style={{
                                      color: DataInfo?.badge_color?.color,
                                    }}
                                  />
                                  {DataInfo?.organizations_status}
                                </div>
                              ) : (
                                <div> {DataInfo.organizations_status} </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      {DataInfo?.code != '-' && (
                        <div className="css_button_filter">
                          <div>
                            <div className="topic_fill">ดูวุฒิบัตร</div>
                            <div>
                              <ButtonOutline onClick={() => OpenPDFFile()}>
                              วุฒิบัตร
                              </ButtonOutline>
                            </div>
                          </div>                        
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">วันที่เริ่มเป็นสมาชิก</div>
                      <div>
                        <TextField
                          name="effective_date_display"
                          className="TextField_employee"
                          value={DataInfo.effective_date_display}
                          onChange={(e) => setDataInfo({ ...DataInfo, effective_date_display: e.target.value })}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">วันที่หมดอายุ</div>
                      <div>
                        <TextField
                          name="expiring_at_display"
                          className="TextField_employee"
                          value={DataInfo.expiring_at_display}
                          onChange={(e) => setDataInfo({ ...DataInfo, expiring_at_display: e.target.value })}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border_header"></div>
            <div className="main_box">
              <div>
                <div className="main_box_text_head">ชื่อบัญชีหลัก</div>
                <div className="main_box_text_sub">รายละเอียดผู้ดูแลบัญชีหลักของแม่ข่าย</div>
              </div>
              <div>
                <div className="main_box_edit">
                  <div className="main_box_fill_data">
                    <div>
                      <div className="topic_fill">คำนำหน้า</div>
                      <div>
                          <Dropdown
                            id={`dd_contact_person_prefix_id`}
                            value={DataInfo.contact_person_prefix_id}
                            optionList={title_option}
                            onChange={onTitlecontact_person_prefix_id}
                            placeHolder={'เลือกคำนำหน้า'}
                            disabled={!EditBox3}
                          />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">ชื่อ</div>
                      <div>
                        <TextField
                          name="contact_person_first_name"
                          className="TextField_employee"
                          value={DataInfo.contact_person_first_name}
                          onChange={(e) => setDataInfo({ ...DataInfo, contact_person_first_name: e.target.value })}
                          disabled={!EditBox3}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">นามสกุล</div>
                      <div>
                        <TextField
                          name="contact_person_last_name"
                          className="TextField_employee"
                          value={DataInfo.contact_person_last_name}
                          onChange={(e) => setDataInfo({ ...DataInfo, contact_person_last_name: e.target.value })}
                          disabled={!EditBox3}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="main_box_fill_data">
                    {
                      /*
                      <div>
                      <div className="topic_fill">ตำแหน่ง</div>
                      <div>
                        <TextField
                          name="contact_person_position"
                          className="TextField_employee"
                          value={DataInfo.contact_person_position}
                          onChange={(e) => setDataInfo({ ...DataInfo, contact_person_position: e.target.value })}
                          disabled={!EditBox3}
                        />
                      </div>
                    </div>
                      */
                    }
                    
                    <div>
                      <div className="topic_fill">เบอร์โทรศัพท์</div>
                      <div>
                        <TextField
                          name="contact_person_phone_no"
                          className="TextField_employee"
                          value={DataInfo.contact_person_phone_no}
                          onChange={(e) => setDataInfo({ ...DataInfo, contact_person_phone_no: e.target.value })}
                          disabled={!EditBox3}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">Email ติดต่อ</div>
                      <div>
                        <TextField
                          name="contact_person_email"
                          className="TextField_employee"
                          value={DataInfo.contact_person_email}
                          onChange={(e) => setDataInfo({ ...DataInfo, contact_person_email: e.target.value })}
                          disabled={!EditBox3}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    /*
                                      <div className="border_buttom"></div>
                  <div className="main_box_button">
                    {EditBox3 == false && (
                      <div>
                        <Button onClick={() => setEditBox3(true)} >
                          แก้ไขข้อมูล
                        </Button>
                      </div>
                    )}
                    {EditBox3 == true && (
                      <div>
                        <ButtonSecondary onClick={() => setEditBox3(false)} className="md-right-1rem">
                          ยกเลิก
                        </ButtonSecondary>
                        <Button onClick={() => Savechanges(3)}>บันทึก</Button>
                      </div>
                    )}
                  </div>
                    */
                  }

                </div>
              </div>
            </div>
          </DivInfo>
        )}


      </header>

    </Div>
  )
}

export default OrgProfile
